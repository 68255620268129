@import '~antd/dist/antd.less';
//@import './theme/index.less';
@import './theme/dark.less';
@import './theme/chrome.scrollbar.less';

.App {
    text-align: center;
}

.App-header {
    /* margin: 16px; */
    padding-left: 10px;
    /* background-color: #282c34; */
    /* min-height: 100vh; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* font-size: calc(10px + 2vmin); */
    color: #ddd;
    font-size: 24px;
}

.App-link {
    color: #61dafb;
}

.content {
    //overflow: auto;
    //overscroll-behavior-y: contain;
    border: 0px solid red;
    padding: 0px 20px 20px 20px;
    //background: #87919b;
}

// .layout {
//     background: #87919b;
// }

// .deploymentsTable {
//     background: #87919b;
// }

.cgi {
    color: rgba(255, 27, 55, 1.0);
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.deployments-page-header {
    border: 0px;
    padding: 0px 0px 10px 0px;
}


.deploymentsTable {
    height: 100%;
    padding: 0px;
    overflow: auto;
    flex: 1;
    margin: 0;
    display: flex //width: 100%;
}

.ant-table-body {
    min-height: calc(100vh - 200px);
}

a[target="_blank"].newtab::after {
    content: "";
    width: 1em;
    height: 1em;
    margin: 0 0.05em 0 0.4em;
    //background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ij48cGF0aCBkPSJNOSAyTDkgMyAxMi4zIDMgNiA5LjMgNi43IDEwIDEzIDMuNyAxMyA3IDE0IDcgMTQgMlpNNCA0QzIuOSA0IDIgNC45IDIgNkwyIDEyQzIgMTMuMSAyLjkgMTQgNCAxNEwxMCAxNEMxMS4xIDE0IDEyIDEzLjEgMTIgMTJMMTIgNyAxMSA4IDExIDEyQzExIDEyLjYgMTAuNiAxMyAxMCAxM0w0IDEzQzMuNCAxMyAzIDEyLjYgMyAxMkwzIDZDMyA1LjQgMy40IDUgNCA1TDggNSA5IDRaIi8+PC9zdmc+) no-repeat;
    background: ~"url('external_open.svg')" no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: text-bottom;
  }


.action-tag {
    //all: unset;
    //color: red !important;
    background: #276193 !important;
    border-color: #276193 !important;
}

.ant-tag-blue {
    border-color: transparent !important;
    background: transparent !important;
}

.links {
    white-space:nowrap;
}
