// Theme info for AntD: https://antdtheme.com/dark
// Names: https://github.com/ant-design/ant-design/blob/master/components/table/style/index.less

//@import './node_modules/antd/dist/antd.less';
//@import '~antd/lib/style/themes/default.less';

@main: #222629;
@main-dark: #191C1E;
@main-light: #2E3338;
@text: #ddd;
@text-light: #fff;
@text-dark: #222;
@text-disabled: #999;

@table-highlight: #286193;
//@table-highlight: rgb(143, 23, 23)

// First
//@primary: #26547C;
@primary: #286193;

@info: #FFD166;
@success: #06D6A0;
@error: #EF476F;
@warn: #E55934;

// Second 
// @primary: #004E64;
// @info: #F2C14E;
// @success: #499F68;
// @error: #DA2C38;
// @warn: #ED5A31;

@layout-header-height: 48px;

@primary-color: @primary;
@processing-color: @info;
@info-color: @info;
@success-color: @success;
@error-color: @error;
@highlight-color: @error;
@warning-color: @warn;
@text-color: @text;
@text-color-dark: @text-dark;
@disabled-color: @text-disabled;
@text-color-secondary: @text-disabled;
@heading-color: @text;
@heading-color-dark: @text-dark;
@link-color: lighten(@primary, 20%);

@body-background: @main;
@component-background: @main-light;
@item-active-bg: @primary;
@item-hover-bg: @primary;
@menu-highlight-color: @primary-color;
//@menu-item-active-bg: #777e82;
@menu-item-active-border-width: 8px;
@table-row-hover-bg: darken(@primary, 10%);
//@table-header-bg: #1d1d1d;
//@table-header-bg: #706f6f;
@table-header-bg: #2a2c2e;
@table-body-sort-bg: #363b3f;

@table-header-sort-bg: @table-highlight;
@table-header-sort-active-bg: @table-highlight;
@table-fixed-header-sort-active-bg: @table-highlight;
@table-expanded-row-bg: @main;


@descriptions-bg: @main-light;
@menu-bg: transparent;

@border-color-base: @main-dark;
@border-color-split: lighten(@main-light, 2%);

@background-color-light: @main-light;
@background-color-base: @main-light;

@checkbox-check-color: @main-light;

@layout-header-background: @main-dark;
@layout-body-background: @main;

// Alert
@alert-success-border-color: @success;
@alert-success-bg-color: darken(@success, 20%);
@alert-success-icon-color: @success;

@alert-info-border-color: @info;
@alert-info-bg-color: darken(@info, 30%);
@alert-info-icon-color: @info;

@alert-warning-border-color: @warn;
@alert-warning-bg-color: darken(@warn, 20%);
@alert-warning-icon-color: @warn;

@alert-error-border-color: @error;
@alert-error-bg-color: darken(@error, 20%);
@alert-error-icon-color: @error;

@input-bg: #191C1E;

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
//   border: 0px solid green;
   -webkit-text-fill-color: #ddd;
//   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
   transition: background-color 5000s ease-in-out 0s;
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

