/* Track */
::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-track {
    //background-color: #16324a;
    background-color: #2d2e2e;
    box-shadow: inset 0 0 8px #000;
}

/* Thumb */
::-webkit-scrollbar-thumb {
    //background-color: #406283;
    background-color: #767879;
    box-shadow: inset 0 0 8px #000;
}

/* Buttons */
::-webkit-scrollbar-button {
    background-color: #2E3338;

    height: 16px;
    width: 16px;
}

::-webkit-scrollbar-button:hover {
    background-color: #191C1E;
}

/* Corner */
::-webkit-scrollbar-corner {
    background-color: #191C1E;
}

/* Up */
::-webkit-scrollbar-button:vertical:decrement {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px"><polygon points="12,12,4,12,8,4" fill="rgb(211,211,211)" /></svg>');
    background-size: 16px 16px;
}

/* Down */
::-webkit-scrollbar-button:vertical:increment {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px"><polygon points="12,4,4,4,8,12" fill="rgb(211,211,211)" /></svg>');
    background-size: 16px 16px;
}

/* Left */
::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px"><polygon points="12,12,12,4,4,8" fill="rgb(211,211,211)" /></svg>');
    background-size: 16px 16px;
}

/* Right */
::-webkit-scrollbar-button:horizontal:increment {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16px" height="16px"><polygon points="4,4,4,12,12,8" fill="rgb(211,211,211)" /></svg>');
    background-size: 16px 16px;
}